<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="companyUsers"
      :items-per-page="50"
      class="elevation-1"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt, "dd MMM yyyy - HH:mm") }}
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <RoleSelector v-model="item.userRoleId" :user-id="item.userId" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          dark
          color="primary"
          @click="clickedRow(item)"
          v-if="item.userId === currentUser.userId || permitted('User.Update')"
          >edit</v-icon
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RoleSelector from "../roles/RoleSelector.vue";

export default {
  name: "CompanyUsers",

  components: { RoleSelector },

  data() {
    return {
      headers: [
        { text: this.$t("user.email"), value: "email" },
        { text: this.$t("common.createdAt"), value: "createdAt" },
        { text: this.$t("user.role"), value: "role" },
        { text: this.$t("user.edit"), value: "actions" },
      ],
    };
  },

  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("companies", ["companyUsers"]),
  },

  methods: {
    ...mapActions("companies", ["getCompanyUsers"]),
    ...mapActions("users", ["updateRole", "getById"]),
    ...mapActions("roles", ["getRolesByCompany"]),

    async clickedRow(item) {
      if (
        item.userId === this.currentUser.userId ||
        this.permitted("User.Update")
      ) {
        await this.getById(item.userId);
        this.$router.push(`/company/user/${item.userId}`);
      }
    },
  },

  async created() {
    await this.getCompanyUsers();
    await this.getRolesByCompany();
  },
};
</script>

<style></style>
